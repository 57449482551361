<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" help-page-component="PurchaseOrdersOverview"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-0 pl-5 pr-4>
            <v-layout row wrap>
                <app-card
                        :fullBlock="true"
                        colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-sheet color="grey lighten-2" v-if="false">
                        <v-toolbar color="grey lighten-2" dense flat>
                            <v-spacer></v-spacer>
                            <ExportTable
                                    :cols-to-delete="{start_col: 15, ncols: 1}"
                                    :has-add-function="$can('create','Po')"
                                    file-name="purchaseorders"
                                    table-id="poTable"
                                    @add-action="newPurchaseOrder"
                            ></ExportTable>
                        </v-toolbar>
                    </v-sheet>
                    <PurchaseOrdersListingNew></PurchaseOrdersListingNew>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import LoadingComponent from "Components/Appic/LoadingComponent";
    import {mapFields} from "vuex-map-fields";
    // import PurchaseOrdersListing from "../../../components/Appic/PurchaseOrdersListing";
    // import ExportTable from "../../../components/Appic/ExportTable";

    const ExportTable = () => import("../../../components/Appic/ExportTable");
    const PurchaseOrdersListingNew = () => ({
        component: import("../../../components/Appic/PurchaseOrdersListingNew"),
        loading: LoadingComponent
    });

    export default {
        name: "PurchaseOrdersOverview",
        components: {ExportTable, PurchaseOrdersListingNew},
        title: '',
        data() {
            return {
                loader: false
            }
        },
        computed: {
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
        },
        methods: {
            newPurchaseOrder(){
                let tab = window.open('/v1/purchaseorders/add','_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            }
        },
        mounted(){
            this.$title = this.$t('message.titles.pos')
        }
    }
</script>

<style>
    .v-data-table td {
        padding: 0 5px;
    }
    .v-data-table td {
        padding: 0 5px;
        font-size: 0.8rem !important;
    }
    .font-xs {
        font-size: 0.65rem;
    }
    .mt-5px {
        margin-top: 5px !important;
    }
    .mt-6px {
        margin-top: 6px !important;
    }
</style>